<template>
    <div>
        <div class="js-upload uk-placeholder uk-text-center" style="cursor: pointer;" 
                @dragover.prevent.stop
                @drop.prevent="onFilesSelected"
                @click="() => $refs.upload.click()">
            <span uk-icon="icon: cloud-upload"></span>
            <span class="uk-text-middle" 
            > {{$t('Pages.MediaLibrary.PageUpload.DragAndDrop') }} </span>
            <div uk-form-custom>
                <input value="trigger" @click="onClick" ref="upload" type="file" :multiple="multiple" :accept="accept" @change="onFilesSelected">
            </div>
        </div>
        <div class="uk-text-left" v-for="(file,index) in startedFiles" :key="`file-${file.id}`">
            <div class="uk-flex">
                <i v-if="file.percent === 100" class="bi bi-tick" style="color: #72ae35;margin-right:3px; margin-top: 3px"></i>
                <span class="uk-text-meta uk-text-truncate uk-width-9-10">{{ file.name }} </span> 
            </div>
            <div v-if="file.percent !== 100" class="uk-flex">
                <span v-if="!file.uploadSpeed" class="uk-text-left uk-width-expand uk-text-meta"> {{$t('Pages.MediaLibrary.PageUpload.Uploading') }}: 0 bytes/s </span>
                <span v-else class="uk-text-left uk-width-expand uk-text-meta"> {{$t('Pages.MediaLibrary.PageUpload.Uploading') }}: {{ file.uploadSpeed }}/s </span>
                <span class="uk-text-right uk-text-meta"> {{$t('Pages.MediaLibrary.PageUpload.Uploaded') }}: {{ uploadedSize( file.percent, file.size ) | appropriateBytes }} / {{ file.size | appropriateBytes }} </span>
            </div>
            <div v-if="file.percent !== 100" class="uk-flex uk-flex-middle">
                <progress id="js-progressbar" class="uk-progress uk-width-expand uk-margin-small-right uk-margin-remove-bottom" :value="file.percent" max="100"></progress>
                <p class="uk-width-auto uk-margin-small-right uk-text-meta" > {{ file.percent.toFixed( 1 ) }} % </p>
                <p class="uk-width-auto uk-text-meta" style="cursor: pointer;" uk-icon="icon: close" @click="file.cancel" ></p>
            </div>
            <hr class="uk-margin-small" v-if="startedFiles.length > 1 && index !== startedFiles.length-1 " >
        </div>
    </div>
</template>

<script>
import createApiUploaderEvaporate from '../../../components/util/upload';

const dataTransferFilesAdd = ( dataTransferFiles, files ) => files.reduce( ( dataFiles, file ) => {
    if ( dataFiles.every( dataFile => dataFile.name !== file.name ) ) {
        dataFiles.push( file );
    }

    return dataFiles;
}, dataTransferFiles.slice() );

export default {
    data () {
        return {
            queuedFiles: [],
            allFiles: [],
            idCounter: 0,
            startedFiles: [],
            seenFileNames: {},
            cancelledFiles: []
        }
    },
    props: {
        multiple: { type: Boolean, default: true },
        accept: { type: String, default: '*' }
    },
    methods: {
        async onFilesSelected ( event ) {
            let files = event.dataTransfer?.files || event.target?.files;
            files = Array.from( files );
            this.queuedFiles = dataTransferFilesAdd( [], files );
            this.allFiles = dataTransferFilesAdd( this.allFiles, files );
            this.$emit( 'start', files );
            await this.beginUpload(this.queuedFiles);
        },
        async beginUpload ( files ) {
            // Make sure each file has a unique name
            const updatedFiles = files.map( file => {
                let { name } = file;
                const originalName = name;

                this.seenFileNames[name] = ( this.seenFileNames[name] || 0 ) + 1;
                if ( this.seenFileNames[name] > 1 )
                    name = `${name} (${this.seenFileNames[name]})`;

                file = new File([ file ], name, { type: file.type, lastModified: file.lastModified });
                file.originalName = originalName;

                return file;
            });

            await Promise.all( updatedFiles.map( f => this.startUploadingFile( f ) ) );
            this.queuedFiles = [];
        },
        async startUploadingFile ( file ) {
            const fileInfo = {
                id: this.idCounter,
                percent: 0,
                name: file.name,
                size: file.size,
                secondsLeft: NaN,
                uploadSpeed: '',
                remainingSize: file.size,
                complete: false,
                file,

                cancel: () => {
                    const i = this.startedFiles.findIndex( f => f.id === fileInfo.id );
                    if ( i !== -1 )
                        this.startedFiles.splice( i, 1 );

                    if ( fileInfo.evaporate )
                        fileInfo.evaporate.cancel( fileInfo.fileKey ); // cancel the specifc file, if not pass the key it will cancel all the files

                    this.$emit( 'cancel', fileInfo );
                    this.cancelledFiles.push( fileInfo );

                    if ( this.startedFiles.length > 0 && this.startedFiles.every( f => f.complete ) )
                        this.$emit( 'complete', this.startedFiles );
                }
            };
            this.idCounter += 1;
            this.startedFiles.push( fileInfo );

            const onError = err => {
                if ( typeof err === 'string' && err === 'User aborted the upload' )
                    return;

                // This will throw error when the upload is started and then cancelled. Ignore it if it is the cancelled file.
                if ( typeof err === 'string' && err.startsWith( 'Error aborting upload' ) && this.cancelledFiles.length ) {
                    const cancelledIds = this.cancelledFiles.map( item => item.id );
                    if ( cancelledIds.includes( fileInfo.id ) ) {
                        return;
                    }
                }
                fileInfo.percent = -1;
                this.error = new Error( err );
                this.$emit( 'error', err );
            };

            try {
                const data = await this.$store.dispatch('getAWSConfig', { fileName: file.originalName, expectedMediaClass: null });
                const { key: fileName, accessKey, bucket, contentType, region, mediaClass } = data.createUniqueUploadSettings;
                const S3_MAIN = `https://s3-${region}.amazonaws.com/`;
                fileInfo.mediaClass = mediaClass;
                const evaporate = await createApiUploaderEvaporate({ accessKey, bucket, region }, this.$store );
                Object.defineProperty( fileInfo, 'evaporate', { value: evaporate }); // Vue should never observe this

                const awsObjectKey = await evaporate.add({
                    name: fileName,
                    file,
                    contentType,
                    error: onError,
                    started: fileKey => { fileInfo.fileKey = fileKey; },
                    progress: ( progressValue, stats ) => {
                        Object.assign( fileInfo, {
                            remainingSize: stats.remainingSize,
                            totalUploaded: stats.totalUploaded,
                            uploadSpeed: stats.readableSpeed,
                            secondsLeft: stats.secondsLeft,
                            percent: progressValue * 100
                        });

                        this.$emit( 'progress', fileInfo ); // @progress - A file's progress has updated
                    }
                });

                fileInfo.complete = true;
                fileInfo.previewUrl = `${S3_MAIN}${fileInfo.fileKey}`
                fileInfo.path = awsObjectKey;
                if ( this.startedFiles.every( f => f.complete ) )
                    this.$emit( 'complete', this.startedFiles );
            }
            catch ( err ) {
                onError( err );
            }
        },
        uploadedSize ( progress, fileSize ) {
            return progress > -1
                ? Math.round( fileSize * ( progress / 100 ) )
                : fileSize;
        },
        onClick () {
            this.$refs.upload.value = ''; //helps when the user selects the same file immediately
        }
    }
}
</script>
