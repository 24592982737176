import Evaporate from 'evaporate';
import sha256 from 'crypto-js/sha256';
import sparkMD5 from 'spark-md5';

export default async function createApiUploaderEvaporate ( configParams, store ) {
    return Evaporate.create({
        customAuthMethod: async function getSignedKey ( signParams, header, stringToSign ) {
            const signedRequest = store.dispatch('signRequest', { toSign: stringToSign });
            return signedRequest;
        },
        logging: false,
        aws_key: configParams.accessKey,
        bucket: configParams.bucket,
        awsRegion: configParams.region,
        maxFileSize: 150 * 1024 * 1024 * 1024, // 150 GB
        maxConcurrentParts: 10,
        awsSignatureVersion: '4',
        cloudfront: true,
        partSize: 1 * 1024 * 1024 * 1024, // 1 GB
        s3Acceleration: true,
        computeContentMd5: true,
        cryptoMd5Method: d => btoa( sparkMD5.ArrayBuffer.hash( d, true ) ),
        cryptoHexEncodedHash256: sha256
    });
}
